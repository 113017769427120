<template>
  <a-layout id="components-layout-demo-top-side">
    <a-layout-header class="header">
      <CompanyHeader></CompanyHeader>
    </a-layout-header>

    <a-layout-content style="">
      <div class="content_banner">
      <a href="https://www.zxxldy.com/"> <img src="../static/wulutbanner.png" /></a> 
      </div>
      <div class="content_course">
        <div>课程中心</div>
        <div class="content_course_border"></div>
        <div class="content_course_list">
          <ul>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course1.png"
              />
              </a>
              
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course2.png"
              />
              </a>
             
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course3.png"
              />
              </a>
              
            </li>
          </ul>
          <ul>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course4.png"
              />
              </a>
             
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course5.png"
              />
              </a>
              
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wyt_course6.png"
              />
              </a>
             
            </li>
          </ul>
        </div>
      </div>
      <div class="content_course">
        <div>劳动风采</div>
        <div class="content_course_border"></div>
        <div class="content_course_list">
            <ul>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wuyut_ldfc1.jpg"
              />
              </a>
              
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wuyut_ldfc2.jpg"
              />
              </a>
             
            </li>
            <li>
              <a href="https://www.zxxldy.com/">
                <img
                class="content_course_image"
                src="../static/wuyut_ldfc3.jpg"
              />
              </a>
              
            </li>
          </ul>
        </div>
        
      </div>

      <div class="content_course">
        <div>关于我们</div>
        <div class="content_course_border"></div>
        <div class="content_gywm_list">
            <ul>
            <li>
          <p>
            五育通劳动教育云是一家多年来致力于教育信息化及教育教学应用的高新技术企业，为双软认证企业，有多年教育行业经验和技术开发背景，是全国职业教育研究院战略合作单位。
          </p> 
          <p>
            劳动教育被纳入必修课后，为满足学校在劳动教育中面临的实际需求，帮助学校更好地推动和落实劳动教育，公司在全国职业教育研究院的指导与支持下
            ，开发了劳动教育云系统，获得了重庆市颁发的劳动教育实践与创新教学成果一等奖，经过持续不断的研发和实践，得到了众多院系领导及广大师生的认可与好评。
          </p>
            </li>
            <li>
              <img
                class="content_course_image"
                src="../static/wuyut_gywm.jpg"
              />
            </li>
          </ul>
        </div>
        
      </div>

    </a-layout-content>
  </a-layout>
</template>

<script>
import CompanyHeader from "./home/components/companyheader.vue";

export default {
  name: "wulut",
  components: {
    CompanyHeader,
  },
  created() {},
  data() {
    return {};
  },
};
</script>
<style>
/* 适配各种屏幕尺寸 */
@media (min-width: 1024px) {
  body,
  html {
    font-size: 18px !important;
  }
}
@media (min-width: 1100px) {
  body,
  html {
    font-size: 20px !important;
  }
}
@media (min-width: 1280px) {
  body,
  html {
    font-size: 22px !important;
  }
}
@media (min-width: 1366px) {
  body,
  html {
    font-size: 24px !important;
  }
}
@media (min-width: 1440px) {
  body,
  html {
    font-size: 25px !important;
  }
}
@media (min-width: 1680px) {
  body,
  html {
    font-size: 28px !important;
  }
}
@media (min-width: 1920px) {
  body,
  html {
    font-size: 33px !important;
  }
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header {
  height: 2.76rem;
  background-color: #fff;
  box-shadow: 0px 0px 0.42rem 0px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  padding: 0.63rem 1.78rem 0.6rem 1.7rem;
}
.content_banner {
  width: 100%;
  image {
    width: 100%;
    height: auto;
  }
}
.content_course {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_course_border {
  width: 50px;
  border-top: 2px solid #1e69de;
}
.content_course_list {
  width: 60%;
  margin: 20px 20% 0px 20%;
  height: auto;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      width: 32%;
      height: auto;
    }
  }
}
.content_course_image {
  width: 100%;
  height: auto;
}
.content_gywm_list {
  width: 60%;
  margin: 20px 20% 0px 20%;
  height: auto;
  background-color: #fff;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    li {
      width: 48%;
      height: auto;
      p{
        margin-top: 20px;
        text-indent: 20px;
        font-size: 20px;
      }
    }
  }
}
</style>